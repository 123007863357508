function latepoint_mask_timefield($elem){
	if(jQuery().inputmask){
	  $elem.inputmask({
	      'mask': '99:99',
	      'placeholder': 'HH:MM'
	  });
	}
}

function latepoint_mask_percent($elem){
	if(jQuery().inputmask){
	  $elem.inputmask({
			'alias': 'decimal',
			'radixPoint': latepoint_helper.decimal_separator,
			'digits': latepoint_helper.number_of_decimals,
			'digitsOptional': false,
			'suffix': '%',
			'placeholder': '0',
			'rightAlign': false
	  });
	}
}


function latepoint_mask_money($elem){
	if(jQuery().inputmask){
	  $elem.inputmask({
			'alias': 'currency',
			'groupSeparator': latepoint_helper.thousand_separator,
			'radixPoint': latepoint_helper.decimal_separator,
			'digits': latepoint_helper.number_of_decimals,
			'digitsOptional': false,
			'prefix': latepoint_helper.currency_symbol_before ? latepoint_helper.currency_symbol_before + ' ' : '',
			'suffix': latepoint_helper.currency_symbol_after ? ' ' + latepoint_helper.currency_symbol_after : '',
			'placeholder': '0',
			'rightAlign': false
	  });
	}
}

function latepoint_mask_date($elem){
	if(jQuery().inputmask){
	  $elem.inputmask({
			'alias': 'datetime',
			'inputFormat' : latepoint_helper.date_format_for_js
	  });
	}
}

function latepoint_mask_phone($elem){
	if(latepoint_is_phone_masking_enabled() && jQuery().inputmask) $elem.inputmask(latepoint_get_phone_format());
}


function latepoint_get_phone_format(){
  return latepoint_helper.phone_format;
}

function latepoint_is_phone_masking_enabled(){
	return (latepoint_helper.enable_phone_masking == 'yes');
}

function latepoint_show_booking_end_time(){
	return (latepoint_helper.show_booking_end_time == 'yes');
}

function latepoint_init_form_masks(){
	if(latepoint_is_phone_masking_enabled()) latepoint_mask_phone(jQuery('.os-mask-phone'));
}